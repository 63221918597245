import { useState, useCallback, useEffect, useRef } from "react";
import { IPlaceholderHighlighted } from "modules/Placeholder/models";
import { useHighlighting } from "./useHighlighting";
import { getTextNodesIn } from "modules/Placeholder/utils";

interface CaretPosition {
  display: number | null;
  data: number | null;
}

interface HistoryItem {
  value: string;
  cursorPosition: CaretPosition;
}

export const usePlaceholderEditor = (
  value: string,
  onChange: (value: string) => void
) => {
  const [localValue, setLocalValue] = useState(value);
  const [history, setHistory] = useState<HistoryItem[]>([
    { value, cursorPosition: { display: null, data: null } },
  ]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const editorRef = useRef<HTMLDivElement>(null);
  const displayValueRef = useRef<string>(value);
  const dataValueRef = useRef<string>(value);
  const caretPositionRef = useRef<CaretPosition>({ display: null, data: null });

  //   console.log("component displayValueRef", displayValueRef.current);
  //   console.log("component dataValueRef", dataValueRef.current);

  const { highlightPlaceholders } = useHighlighting();

  const getCaretPosition = useCallback(() => {
    if (
      caretPositionRef.current.display !== null &&
      caretPositionRef.current.data !== null
    ) {
      return caretPositionRef.current;
    }

    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0 || !editorRef.current)
      return { display: null, data: null };

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(editorRef.current);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    const displayPosition = preCaretRange.toString().length;

    // Calculate data position based on display position
    let dataPosition = displayPosition;
    const displayText = displayValueRef.current;
    const dataText = dataValueRef.current;

    for (
      let i = 0, j = 0;
      i < displayPosition && j < dataText.length;
      i++, j++
    ) {
      if (displayText[i] !== dataText[j]) {
        while (j < dataText.length && dataText[j] !== displayText[i]) {
          j++;
        }
      }
      dataPosition = j;
    }

    // After calculating, update caretPositionRef
    caretPositionRef.current = { display: displayPosition, data: dataPosition };
    return caretPositionRef.current;
  }, [editorRef]);

  const restoreCaretPosition = useCallback(
    (positions: { display: number | null; data: number | null }) => {
      if (positions.display === null || !editorRef.current) return;

      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(editorRef.current);
      const textNodes = getTextNodesIn(editorRef.current);

      let currentOffset = 0;
      let done = false;

      for (let i = 0; i < textNodes.length && !done; i++) {
        const node = textNodes[i];
        const nodeLength = node.textContent?.length || 0;

        if (nodeLength === 0) continue; // Skip empty text nodes

        if (currentOffset + nodeLength >= positions.display) {
          range.setStart(node, positions.display - currentOffset);
          range.setEnd(node, positions.display - currentOffset);
          done = true;
        } else {
          currentOffset += nodeLength;
        }
      }

      if (!done) {
        range.setStart(editorRef.current, editorRef.current.childNodes.length);
        range.collapse(true); // Set the caret at the end if position isn't found
      }

      selection?.removeAllRanges();
      selection?.addRange(range);
    },
    [editorRef]
  );

  const addPlaceholder = useCallback(
    (placeholder: string | string[]) => {
      const placeholderText = Array.isArray(placeholder)
        ? placeholder.join(" ")
        : placeholder;

      //   console.log("placeholderText", placeholderText);

      // Convert the placeholder to its display equivalent
      const convertedPlaceholder = placeholderText.replace(
        /{{(.*?)}}/g,
        (match) => {
          return (
            IPlaceholderHighlighted[
              match as keyof typeof IPlaceholderHighlighted
            ] || match
          );
        }
      );

      //   console.log("convertedPlaceholder", convertedPlaceholder);

      const caretPositions = getCaretPosition();

      //   console.log("caretPositions", caretPositions);

      // Check if the editor is empty
      const isEditorEmpty = !displayValueRef.current && !dataValueRef.current;

      //   console.log("isEditorEmpty", isEditorEmpty);
      //   console.log("displayValueRef.current", displayValueRef.current);
      //   console.log("dataValueRef.current", dataValueRef.current);
      //   console.log("caretPositions", caretPositions);

      // If there's no focus but content exists, or if caret is at the start, set caret to the start
      if (
        (caretPositions.display === 0 || caretPositions.display === 1) &&
        !isEditorEmpty
      ) {
        // console.log("caret is at the start");
        caretPositions.display = 0;
        caretPositions.data = 0;
      }

      // Update displayValueRef and dataValueRef
      const newDisplayValue = isEditorEmpty
        ? convertedPlaceholder
        : caretPositions.display !== null
        ? displayValueRef.current.slice(0, caretPositions.display) +
          convertedPlaceholder +
          displayValueRef.current.slice(caretPositions.display)
        : displayValueRef.current + convertedPlaceholder;

      //   console.log("newDisplayValue", newDisplayValue);

      const newDataValue = isEditorEmpty
        ? placeholderText
        : caretPositions.data !== null
        ? dataValueRef.current.slice(0, caretPositions.data) +
          placeholderText +
          dataValueRef.current.slice(caretPositions.data)
        : dataValueRef.current + placeholderText;

      //   console.log("newDataValue", newDataValue);

      setLocalValue(newDataValue);
      dataValueRef.current = newDataValue;
      displayValueRef.current = newDisplayValue;

      onChange(newDataValue);

      // Calculate the new cursor positions
      const newPositions = {
        display: isEditorEmpty
          ? convertedPlaceholder.length
          : caretPositions.display !== null
          ? caretPositions.display + convertedPlaceholder.length
          : newDisplayValue.length,
        data: isEditorEmpty
          ? placeholderText.length
          : caretPositions.data !== null
          ? caretPositions.data + placeholderText.length
          : newDataValue.length,
      };

      //   console.log("newPositions", newPositions);

      // Update history
      setHistory((prev) => [
        ...prev.slice(0, historyIndex + 1),
        { value: newDataValue, cursorPosition: newPositions },
      ]);
      setHistoryIndex((prev) => prev + 1);

      if (editorRef.current) {
        editorRef.current.innerHTML = highlightPlaceholders(newDisplayValue);

        setTimeout(() => {
          // Focus the editor and restore caret position
          editorRef.current?.focus();
          restoreCaretPosition(newPositions);
        }, 0);
      }

      // Update caretPositionRef
      caretPositionRef.current = newPositions;
    },
    [
      getCaretPosition,
      onChange,
      highlightPlaceholders,
      restoreCaretPosition,
      historyIndex,
      dataValueRef,
      displayValueRef,
      caretPositionRef,
    ]
  );

  // Check for placeholders and update dataValue if necessary
  const updateDataValueWithPlaceholders = useCallback(
    (displayValue: string, dataValue: string): string => {
      const placeholderRegex = /{{(.*?)}}/g;
      let match;
      let lastIndex = 0;
      let updatedDataValue = "";

      while ((match = placeholderRegex.exec(displayValue)) !== null) {
        //   console.log("match", match);
        const placeholderContent = match[0];

        // console.log("placeholderContent", placeholderContent);

        // Add text before the placeholder
        updatedDataValue += dataValue.slice(lastIndex, match.index);

        //   console.log("updatedDataValue", updatedDataValue);

        // Check if the placeholder exists in IPlaceholderHighlightedReversed
        const dataPlaceholder = Object.entries(IPlaceholderHighlighted).find(
          ([_, value]) => value === placeholderContent
        );

        // console.log("dataPlaceholder", dataPlaceholder);

        if (dataPlaceholder) {
          // If found, use the key (original placeholder)
          updatedDataValue += dataPlaceholder[0];
        } else {
          // If not found, keep the display value
          updatedDataValue += placeholderContent;
        }

        lastIndex = match.index + placeholderContent.length;
      }

      // Add any remaining text after the last placeholder
      updatedDataValue += dataValue.slice(lastIndex);

      return updatedDataValue;
    },
    []
  );

  const handleInput = useCallback(() => {
    if (editorRef.current) {
      const newDisplayValue = editorRef.current.innerText;
      let newDataValue = newDisplayValue;

      //   console.log("newDisplayValue", newDisplayValue);
      //   console.log("currentDataValue", newDataValue);

      // Update data value with placeholders
      newDataValue = updateDataValueWithPlaceholders(
        newDisplayValue,
        newDataValue
      );

      //   console.log("updatedDataValue", newDataValue);

      if (newDataValue !== dataValueRef.current) {
        // Get the current caret position before any modifications
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        const preCaretRange = range?.cloneRange();
        if (preCaretRange && range) {
          preCaretRange.selectNodeContents(editorRef.current);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          const currentCaretPosition = {
            display: preCaretRange.toString().length,
            data: preCaretRange.toString().length,
          };

          //   console.log("currentCaretPosition", currentCaretPosition);

          dataValueRef.current = newDataValue;
          displayValueRef.current = newDisplayValue;

          //   console.log("lastDataValue", dataValueRef.current);
          //   console.log("lastDisplayValue", displayValueRef.current);

          setLocalValue(newDataValue);
          onChange(newDataValue);

          // Update history
          setHistory((prev) => [
            ...prev.slice(0, historyIndex + 1),
            { value: newDataValue, cursorPosition: currentCaretPosition },
          ]);
          setHistoryIndex((prev) => prev + 1);

          // Store the current scroll position
          const scrollTop = editorRef.current.scrollTop;

          // Highlight placeholders
          if (newDisplayValue) {
            editorRef.current.innerHTML =
              highlightPlaceholders(newDisplayValue);
          } else {
            editorRef.current.innerHTML = "";
          }

          // Restore the scroll position
          editorRef.current.scrollTop = scrollTop;

          // Restore caret position after highlighting
          restoreCaretPosition(currentCaretPosition);

          // Update caretPositionRef after restoring the position
          caretPositionRef.current = currentCaretPosition;

          //   console.log("finalCaretPosition", caretPositionRef.current);
        }
      }
    }
  }, [
    dataValueRef,
    displayValueRef,
    onChange,
    restoreCaretPosition,
    historyIndex,
    highlightPlaceholders,
    updateDataValueWithPlaceholders,
  ]);

  const handlePlaceholderDeletion = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Backspace" || event.key === "Delete") {
        const selection = window.getSelection();
        if (!selection || !editorRef.current) return;

        const range = selection.getRangeAt(0);
        const startContainer = range.startContainer;
        const endContainer = range.endContainer;

        // Check if there's a non-collapsed selection
        if (!range.collapsed) {
          event.preventDefault();
          // Remove the entire selection
          range.deleteContents();
          handleInput();
          return;
        }

        // Handle single placeholder deletion
        const placeholderSpan =
          startContainer.parentElement?.closest("span[data-original]") ||
          endContainer.parentElement?.closest("span[data-original]");

        if (placeholderSpan) {
          event.preventDefault();

          if (event.key === "Backspace") {
            // If cursor is at the start of the placeholder, move it before
            if (range.startOffset === 0) {
              range.setStartBefore(placeholderSpan);
              range.setEndBefore(placeholderSpan);
            }
          } else if (event.key === "Delete") {
            // If cursor is at the end of the placeholder, move it after
            if (range.startOffset === placeholderSpan.textContent?.length) {
              range.setStartAfter(placeholderSpan);
              range.setEndAfter(placeholderSpan);
            }
          }

          placeholderSpan.remove();
          selection.removeAllRanges();
          selection.addRange(range);
          handleInput();
        }
      }
    },
    [handleInput]
  );

  const handleUndoRedo = useCallback(
    (isUndo: boolean) => {
      const newIndex = isUndo ? historyIndex - 1 : historyIndex + 1;
      if ((isUndo && newIndex >= 0) || (!isUndo && newIndex < history.length)) {
        const historyItem = history[newIndex];
        setLocalValue(historyItem.value);
        dataValueRef.current = historyItem.value;
        displayValueRef.current = historyItem.value;
        setHistoryIndex(newIndex);

        if (editorRef?.current) {
          editorRef.current.innerHTML = highlightPlaceholders(
            historyItem.value
          );
          restoreCaretPosition(historyItem.cursorPosition);
          onChange(historyItem.value);
        }
      }
    },
    [
      dataValueRef,
      displayValueRef,
      highlightPlaceholders,
      history,
      historyIndex,
      onChange,
      restoreCaretPosition,
    ]
  );

  const insertLineBreak = useCallback(() => {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    if (range) {
      const br = document.createElement("br");
      const space = document.createTextNode("\u200B");
      range.deleteContents();
      range.insertNode(br);
      range.insertNode(space);
      range.setStartAfter(space);
      range.setEndAfter(space);
      selection?.removeAllRanges();
      selection?.addRange(range);
      handleInput();
    }
  }, [handleInput]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      handlePlaceholderDeletion(event);

      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        insertLineBreak();
      } else if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "z" || event.key === "y")
      ) {
        event.preventDefault();
        handleUndoRedo(event.key === "z");
      }
    },
    [handlePlaceholderDeletion, insertLineBreak, handleUndoRedo]
  );

  // Modify copy behavior to use data value
  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      if (
        editorRef.current &&
        editorRef.current.contains(document.activeElement)
      ) {
        event.preventDefault();
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const selectedText = range.toString();
          const displayText = editorRef.current.innerText;
          const dataText = dataValueRef.current;

          let copiedText = "";
          let displayIndex = 0;
          let dataIndex = 0;

          while (displayIndex < displayText.length) {
            if (displayText.startsWith("{{", displayIndex)) {
              const displayPlaceholderEnd = displayText.indexOf(
                "}}",
                displayIndex
              );
              const dataPlaceholderEnd = dataText.indexOf("}}", dataIndex);

              if (displayPlaceholderEnd !== -1 && dataPlaceholderEnd !== -1) {
                const displayPlaceholder = displayText.substring(
                  displayIndex,
                  displayPlaceholderEnd + 2
                );
                const dataPlaceholder = dataText.substring(
                  dataIndex,
                  dataPlaceholderEnd + 2
                );

                if (selectedText.includes(displayPlaceholder)) {
                  copiedText += dataPlaceholder;
                }

                displayIndex = displayPlaceholderEnd + 2;
                dataIndex = dataPlaceholderEnd + 2;
              } else {
                copiedText += dataText[dataIndex];
                displayIndex++;
                dataIndex++;
              }
            } else {
              if (selectedText.includes(displayText[displayIndex])) {
                copiedText += dataText[dataIndex];
              }
              displayIndex++;
              dataIndex++;
            }
          }

          event.clipboardData?.setData("text/plain", copiedText);
        }
      }
    };

    document.addEventListener("copy", handleCopy);
    return () => document.removeEventListener("copy", handleCopy);
  }, [dataValueRef]);

  // Set initial content
  useEffect(() => {
    if (editorRef.current && value) {
      if (value) {
        editorRef.current.innerHTML = highlightPlaceholders(value);
      } else {
        editorRef.current.innerHTML = "";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetEditor = () => {
    const newValue = "";
    setLocalValue(newValue);
    if (editorRef.current) {
      editorRef.current.innerHTML = newValue;
    }
  };

  return {
    localValue,
    editorRef,
    handleInput,
    handleKeyDown,
    handleUndoRedo,
    addPlaceholder,
    highlightPlaceholders,
    restoreCaretPosition,
    resetEditor,
  };
};
