import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { GlobalContext } from "core/context";
import { TitleDropdownVariant } from "core/models";
import { GlobalActionType } from "core/reducers";
import RouterConstants from "core/routes/constants";
import { formatUrlToHideProtocol } from "core/utils/commonHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import AddSearchPeopleToCampaign from "modules/Campaign/components/AddSearchPeopleToCampaign";
import AddSelectedSearchResultsToCampaign from "modules/Campaign/components/AddSelectedSearchResultsToCampaign";
import { CampaignQuery } from "modules/Campaign/models";
import ExportSearchButton from "modules/Search/components/ExportSearchButton";
import SearchResultList from "modules/Search/components/SearchDetail";
import {
  ISearchFormValues,
  SearchStatusMatchToVariant,
} from "modules/Search/models";
import SearchService from "modules/Search/services";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import NotFound from "ui-kit/components/NotFound";
import PageHeader from "ui-kit/components/PageHeader";

type ParamTypes = {
  searchId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
  },
  title: {
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 600,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 700,
    },
  },
  link: {
    padding: theme.spacing(1, 2),
    borderRadius: 4,
    backgroundColor: theme.app.palette.action.hoverBackground,
    display: "grid",
    gridTemplateColumns: "28px 1fr",
    width: "fit-content",
  },
  icon: {
    fontSize: 20,
    color: theme.app.palette.action.color,
  },
  url: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 250,
      transition: theme.transitions.create("width", {
        duration: theme.transitions.duration.standard,
      }),
      "&:hover": {
        width: 550,
        transition: theme.transitions.create("width", {
          delay: theme.transitions.duration.standard,
          duration: theme.transitions.duration.standard,
        }),
      },
    },
  },
  chip: {
    marginBottom: theme.spacing(1.5),
  },
}));

const SearchDetail = (): React.ReactElement | null => {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const params = useParams<ParamTypes>();
  const searchId: number = +params.searchId;
  const [openCampaignDialog, toggleCampaignDialog] = React.useState(false);
  const [openSelectedDialog, toggleSelectedDialog] = React.useState(false);

  const {
    dispatch,
    global: { selected },
  } = React.useContext(GlobalContext);

  const { data, error } = useQuery(["search", searchId], async () => {
    try {
      const response = await SearchService.fetchSearch(searchId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  });

  const resetSelected = () => {
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
  };

  // Delete person from search
  const deleteSearchResult = useMutation(
    (id: number) => SearchService.deleteSearchResult(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["search-results", searchId]);
        // Reset selected searchResults
        snackbarHandler.success("Successfully removed!");
        resetSelected();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateSearchRemove = useMutation(
    () => SearchService.deleteSearch(searchId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["searches"]);
        history.push(RouterConstants.SEARCH.ALL);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleSearchRemove = () => {
    mutateSearchRemove.mutate();
  };

  const mutateSearchName = useMutation(
    (data: ISearchFormValues) => SearchService.updateSearch(searchId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["search", searchId]);
        queryClient.invalidateQueries(["searches"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleSearchNameUpdate = (data: ISearchFormValues) => {
    mutateSearchName.mutate(data);
  };

  const handleRemove = () => {
    if (selected.length === 0) {
      return snackbarHandler.warning("Please select at least 1 person.");
    }
    return selected.forEach(
      ({ name }) => name && deleteSearchResult.mutate(Number(name))
    );
  };

  const handleToggleSelectedDialog = () => {
    if (selected.length === 0) {
      return snackbarHandler.warning("Please select at least 1 person.");
    }
    return toggleSelectedDialog(true);
  };

  const handleToggleCampaignDialog = () => {
    return toggleCampaignDialog(true);
  };

  const handleCloseCampaignDialog = () => {
    toggleCampaignDialog(false);
    queryClient.invalidateQueries(CampaignQuery.campaigns_stats);
  };

  const handleCloseSelectedDialog = () => {
    toggleSelectedDialog(false);
    queryClient.invalidateQueries(CampaignQuery.campaigns_stats);

    resetSelected();
  };

  if (error) {
    return <NotFound label="search" link={RouterConstants.SEARCH.ALL} />;
  }

  const selectedActions =
    !!data?.status &&
    [
      SearchStatusMatchToVariant.processed,
      SearchStatusMatchToVariant.processing,
    ].includes(data?.status) ? (
      <>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          type="button"
          startIcon={<ControlPointIcon />}
          onClick={handleToggleSelectedDialog}
        >
          Add to campaign
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          type="button"
          startIcon={<DeleteIcon />}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </>
    ) : undefined;

  const customActions =
    !!data?.status &&
    [
      SearchStatusMatchToVariant.processed,
      SearchStatusMatchToVariant.processing,
    ].includes(data?.status) ? (
      <>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          type="button"
          startIcon={<ControlPointIcon />}
          onClick={handleToggleCampaignDialog}
        >
          Add all to campaign
        </Button>
        <ExportSearchButton searchId={searchId} searchName={data?.name} />
      </>
    ) : undefined;

  return (
    <>
      <PageHeader
        backlinkProps={{
          text: "Searches",
          link: RouterConstants.SEARCH.ALL,
        }}
        menuProps={[
          {
            id: TitleDropdownVariant.rename,
            label: "Rename",
            icon: EditIcon,
            func: handleSearchNameUpdate,
          },
          {
            id: TitleDropdownVariant.delete,
            label: "Delete",
            icon: DeleteIcon,
            func: handleSearchRemove,
          },
        ]}
        filterProps
        title={data?.name}
        body={
          data?.strategy_data?.url && (
            <>
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.link}
                component="span"
              >
                <LinkIcon className={classes.icon} />
                <a
                  href={data?.strategy_data?.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={classes.url}
                >
                  {formatUrlToHideProtocol(data?.strategy_data?.url)}
                </a>
              </Typography>
            </>
          )
        }
        hiddenDivider
        selectedActions={selectedActions}
        customAction={customActions}
      />
      {!!data && <SearchResultList search={data} />}
      <AddSelectedSearchResultsToCampaign
        search_results={selected.map((item) => Number(item.name))}
        open={openSelectedDialog}
        handleClose={handleCloseSelectedDialog}
      />
      <AddSearchPeopleToCampaign
        searchId={searchId}
        open={openCampaignDialog}
        handleClose={handleCloseCampaignDialog}
      />
    </>
  );
};

export default SearchDetail;
