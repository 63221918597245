import {
  dayjs,
  getCurrentTimezoneDate,
  formatDayjsToStr,
} from "core/utils/dateHandler";
import { IRange, RangeDefaultLabels, RangeDefaults } from "./models";

const createDefaults = (timezone: string) => {
  const date = getCurrentTimezoneDate(timezone); // Pass timezone parameter

  return {
    startOfToday: date.startOf("day"),
    endOfToday: date.endOf("day"),
    startOfYesterday: date.subtract(1, "day").startOf("day"),
    endOfYesterday: date.subtract(1, "day").endOf("day"),
    startOfWeek: date.startOf("isoWeek"),
    endOfWeek: date.endOf("isoWeek"),
    startOfLastWeek: date.subtract(7, "day").startOf("isoWeek"),
    endOfLastWeek: date.subtract(7, "day").endOf("isoWeek"),
    startOfMonth: date.startOf("month"),
    endOfMonth: date.endOf("month"),
    startOfLastMonth: date.subtract(1, "month").startOf("month"),
    endOfLastMonth: date.subtract(1, "month").endOf("month"),
    startOfLast7Days: date.subtract(6, "day"),
    startOfLast30Days: date.subtract(29, "day"),
  };
};

const staticRangeHandler = {
  range: () => ({ startDate: null, endDate: null, main: undefined }),
  isSelected(range: IRange) {
    const definedRange = this.range();

    // If end date null to enable auto-close, set the end-date to start-date
    if (definedRange.endDate === null) {
      definedRange.endDate = definedRange.startDate;
    }

    const definedStartDate = formatDayjsToStr(definedRange.startDate);
    const definedEndDate = formatDayjsToStr(definedRange.endDate);

    const startDate = formatDayjsToStr(dayjs(range.startDate));
    const endDate = formatDayjsToStr(dayjs(range.endDate));

    return startDate === definedStartDate && endDate === definedEndDate;
  },
};

export function createStaticRanges(ranges: any) {
  return ranges.map((range: any) => ({
    ...staticRangeHandler,
    ...range,
  }));
}

export const getDefaultDates = (timezone: string) => {
  const defaults = createDefaults(timezone);

  return {
    [RangeDefaults["last-7-days"]]: {
      startDate: defaults.startOfLast7Days,
      endDate: defaults.endOfToday,
      main: RangeDefaults["last-7-days"],
    },
    [RangeDefaults["last-30-days"]]: {
      startDate: defaults.startOfLast30Days,
      endDate: defaults.endOfToday,
      main: RangeDefaults["last-30-days"],
    },
    // [RangeDefaults["today"]]: {
    //   startDate: defaults.startOfToday,
    //   endDate: null, // Here to enable auto-close on selection,
    //   main: RangeDefaults["today"],
    // },
    // [RangeDefaults["yesterday"]]: {
    //   startDate: defaults.startOfYesterday,
    //   endDate: null, // Here to enable auto-close on selection,
    //   main: RangeDefaults["yesterday"],
    // },
    // [RangeDefaults["this-week"]]: {
    //   startDate: defaults.startOfWeek,
    //   endDate: defaults.endOfToday,
    //   main: RangeDefaults["this-week"],
    // },
    // [RangeDefaults["last-week"]]: {
    //   startDate: defaults.startOfLastWeek,
    //   endDate: defaults.endOfLastWeek,
    //   main: RangeDefaults["last-week"],
    // },
    [RangeDefaults["this-month"]]: {
      startDate: defaults.startOfMonth,
      endDate: defaults.endOfToday,
      main: RangeDefaults["this-month"],
    },
    [RangeDefaults["last-month"]]: {
      startDate: defaults.startOfLastMonth,
      endDate: defaults.endOfLastMonth,
      main: RangeDefaults["last-month"],
    },
    [RangeDefaults["custom-range"]]: {
      startDate: null,
      endDate: null,
      main: RangeDefaults["custom-range"],
    },
  };
};

export const getDefaultStaticRanges = (timezone: string) => {
  const defaults = getDefaultDates(timezone);

  return createStaticRanges([
    {
      label: RangeDefaultLabels["last-7-days"],
      range: () => defaults[RangeDefaults["last-7-days"]],
    },
    // {
    //   label: RangeDefaultLabels["today"],
    //   range: () => defaultDates[RangeDefaults["today"]],
    // },
    {
      label: RangeDefaultLabels["last-30-days"],
      range: () => defaults[RangeDefaults["last-30-days"]],
    },

    // {
    //   label: RangeDefaultLabels["yesterday"],
    //   range: () => defaultDates[RangeDefaults["yesterday"]],
    // },
    // {
    //   label: RangeDefaultLabels["this-week"],
    //   range: () => defaultDates[RangeDefaults["this-week"]],
    // },
    // {
    //   label: RangeDefaultLabels["last-week"],
    //   range: () => defaultDates[RangeDefaults["last-week"]],
    // },
    {
      label: RangeDefaultLabels["this-month"],
      range: () => defaults[RangeDefaults["this-month"]],
    },
    {
      label: RangeDefaultLabels["last-month"],
      range: () => defaults[RangeDefaults["last-month"]],
    },
    {
      label: RangeDefaultLabels["custom-range"],
      range: () => defaults[RangeDefaults["custom-range"]],
    },
    // {
    //   label: "Last 3 Months",
    //   range: () => ({
    //     startDate: defaults.startofLastThreeMonths,
    //     endDate: defaults.endOfLastThreeMonths,
    //   }),
    // },
    // {
    //   label: "Last 6 Months",
    //   range: () => ({
    //     startDate: defaults.startofLastSixMonths,
    //     endDate: defaults.endOfLastSixMonths,
    //   }),
    // },
    // {
    //   label: "Last Year",
    //   range: () => ({
    //     startDate: defaults.startOfLastYear,
    //     endDate: defaults.endOfLastYear,
    //   }),
    // }
  ]);
};
