import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { getFilledPercentage } from "modules/Report/utils";
import { Box } from "@mui/material";
import {
  CampaignFailureCategory,
  CampaignFailureCategoryLabels,
  CampaignFailures,
} from "modules/Campaign/models";
import StatsTooltip, { StatsTooltipItem } from "./StatsTooltip";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
  },
  container: {
    padding: theme.spacing(2, 3),
    width: "fit-content",
    borderRadius: 4,
    backgroundColor: theme.app.palette.action.hoverBackground,
  },
  progressText: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(0.25),
  },
  progressTextHiddenBar: {
    marginTop: 0,
    marginBottom: 0,
  },
  progressLabel: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(-0.5),
    minWidth: 120,
  },
  progressNoLabel: {
    minWidth: 100,
    maxWidth: 120,
  },
  label: {
    lineHeight: 1.5,
  },
  labelNoLabel: {
    fontSize: "0.775rem",
  },
  progressBar: {
    borderRadius: 50,
    backgroundColor: theme.palette.action.selected,
    width: "100%",
  },
  progressBarTable: {
    maxWidth: 120,
  },
  filledBar: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  statsContainer: {
    // "&:hover": {
    //   textDecoration: "underline",
    // },
  },
}));

export interface BaseProgressBarProps {
  container?: boolean;
  label?: string;
  innerLabel?: string;
  hideProgressBar?: boolean;
  disableColor?: boolean;
  disableCount?: boolean;
}

interface ProgressBarProps extends BaseProgressBarProps {
  count: number | undefined;
  total: number | undefined;
  totalExtraLabel?: string;
  isLoading: boolean;
  defaultHeight?: number;
  failures?: CampaignFailures;
}

const ProgressBar = ({
  count,
  total,
  totalExtraLabel,
  isLoading,
  container = false,
  hideProgressBar = false,
  disableColor = false,
  disableCount = false,
  label,
  innerLabel,
  defaultHeight,
  failures,
}: ProgressBarProps): React.ReactElement => {
  const classes = useStyles();
  const theme = useTheme();

  const totalFailures = useMemo(() => {
    if (!failures?.by_action_nr) return 0;

    // Sum all failures from step 1 across all categories
    return Object.values(failures.by_action_nr).reduce((sum, categoryData) => {
      return sum + (categoryData["1"] || 0);
    }, 0);
  }, [failures]);

  const tooltipItems = useMemo((): StatsTooltipItem[] | null => {
    if (!failures?.by_action_nr) return null;

    let firstStepFailures: { [key: string]: number } = {};

    // Collect all first step failures by category
    Object.entries(failures.by_action_nr).forEach(([category, steps]) => {
      if (steps["1"]) {
        firstStepFailures[category] = steps["1"];
      }
    });

    const items: StatsTooltipItem[] = [
      {
        label: "Contacted",
        value: count || 0,
        type: "success",
      },
    ];

    if (totalFailures > 0) {
      items.push({
        label: "Failed",
        value: totalFailures,
        type: "error",
      });

      // Add individual failure categories
      Object.entries(firstStepFailures).forEach(([category, failureCount]) => {
        items.push({
          label:
            CampaignFailureCategoryLabels[
              category as CampaignFailureCategory
            ] || category,
          value: failureCount,
          type: "sub-error" as const,
        });
      });
    }

    return items.length > 1 ? items : null; // Only return if we have failures
  }, [failures, count, totalFailures]);

  const height = useMemo(
    () => (defaultHeight ? defaultHeight : !!innerLabel ? 22 : 4),
    [defaultHeight, innerLabel]
  );

  const countDisplay = (
    <div
      className={clsx({
        [classes.progressText]: true,
        [classes.progressTextHiddenBar]: hideProgressBar,
        [classes.progressLabel]: !!label,
        [classes.progressNoLabel]: !label && !hideProgressBar,
        [classes.statsContainer]: !!tooltipItems,
      })}
    >
      {!!label && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
          mr={1}
          className={classes.label}
        >
          {label}
        </Typography>
      )}
      <Typography
        variant="subtitle2"
        mr={1}
        className={clsx(classes.label, {
          [classes.labelNoLabel]: !label && !hideProgressBar,
        })}
        sx={{
          color: disableColor ? "inherit" : theme.palette.text.primary,
        }}
      >
        {count && count.toLocaleString()}
      </Typography>
      <Typography
        variant="body2"
        className={clsx(classes.label, {
          [classes.labelNoLabel]: !label && !hideProgressBar,
        })}
        sx={{
          color: disableColor ? "inherit" : theme.app.palette.action.color,
        }}
        mr={1}
      >
        /
      </Typography>
      <Typography
        variant="body2"
        className={clsx(classes.label, {
          [classes.labelNoLabel]: !label && !hideProgressBar,
        })}
        sx={{
          color: disableColor ? "inherit" : theme.app.palette.action.color,
        }}
      >
        {totalExtraLabel}
        {total && total.toLocaleString()}
      </Typography>
    </div>
  );

  return isLoading ? (
    <Skeleton width={hideProgressBar ? 40 : 90} />
  ) : (
    <div className={clsx(classes.root, { [classes.container]: container })}>
      {!disableCount && countDisplay}

      {!hideProgressBar && (
        <>
          <Box
            className={clsx(classes.progressBar, {
              [classes.progressBarTable]: !(label || innerLabel),
            })}
            sx={{ height }}
          >
            <Box
              className={classes.filledBar}
              sx={{
                width: getFilledPercentage(count, total),
                height,
                backgroundColor: innerLabel
                  ? theme.palette.success.light
                  : theme.palette.secondary.main,
              }}
            />
          </Box>
          <Typography variant="caption" mt={1}>
            {innerLabel}
          </Typography>
        </>
      )}
    </div>
  );
};

ProgressBar.defaultProps = {
  hideProgressBar: undefined,
  totalExtraLabel: undefined,
  disableColor: undefined,
  defaultHeight: undefined,
};

export default ProgressBar;
