import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import RouterConstants from "core/routes/constants";
import CampaignService from "modules/Campaign/services";
import CampaignValidation from "modules/Campaign/validations";
import {
  CampaignDetailTabs,
  CampaignQuery,
  ICampaignSearchResultFormValues,
  ISelectedCampaignFormInput,
} from "modules/Campaign/models";
import { AccountContext } from "modules/Account/context";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import CampaignAutocomplete from "../CampaignAutocomplete";

interface AddSelectedSearchResultsToCampaignProps {
  search_results: number[];
  open: boolean;
  handleClose: () => void;
}

const defaultValues = { campaign: { id: undefined, label: "" } };

const AddSelectedSearchResultsToCampaign = ({
  search_results,
  open,
  handleClose,
}: AddSelectedSearchResultsToCampaignProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const schema = CampaignValidation.createCampaignPeopleSchema;
  const { errors, control, handleSubmit, reset, setError } =
    useForm<ISelectedCampaignFormInput>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const mutateOnSubmit = useMutation(
    (data: ICampaignSearchResultFormValues) =>
      CampaignService.createCampaignSearchResults(data),
    {
      onSuccess: (_, credentials) => {
        queryClient.invalidateQueries([CampaignQuery.interactions]);
        snackbarHandler.success("Successfully added!");
        handleClose();
        reset(defaultValues);
        history.push(
          RouterConstants.CAMPAIGN.detail(
            credentials.campaign,
            CampaignDetailTabs.audience
          )
        );
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = ({ campaign }: ISelectedCampaignFormInput) => {
    mutateOnSubmit.mutate({
      search_results,
      campaign: campaign.id,
      account: accountId,
    });
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader
          title="Assign people to a campaign"
          onHandleClose={handleClose}
        />
        <DialogContent dividers>
          <DialogContentText>
            Selected people will be assigned to the campaign.
          </DialogContentText>
          <Box marginTop={4.5}>
            <CampaignAutocomplete control={control} errors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Assign people
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSelectedSearchResultsToCampaign;
